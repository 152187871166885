/* Provide sufficient contrast against white background */

@font-face {
    font-family: 'AlMushaf';
    src: local('AlMushaf'), url(fonts/Al_Mushaf.ttf) format('woff');
}
@font-face {
    font-family: 'Majidi_v5';
    src: local('Majidi_v5'), url(fonts/Majidi_v5.ttf) format('woff');
}
@font-face {
    font-family: 'Alvi';
    src: local('Alvi'), url(fonts/alvi_Nastaleeq_Lahori_shipped.ttf) format('woff');
}
/*
   body{background-image:url('Pattrens/pattren1.jpg')}*/
.padding-10{
    padding:10px;
}
.margin-left-10{
    margin-left:10px;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.bgcolor {
    background-color: #28a745;
}
.color {
    color: #28a745 !important;
    font-weight:bold;
}
.table-responsive{direction:rtl;}
.modal {
    font-size: 12px;
    display: block;
    position: relative;
}

    .modal > .header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
        margin-left: 6px;
        margin-right: 6px;
    }
    .modal > .header_review {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
        margin-left: 6px;
        margin-right: 6px;
    }

    .modal > .content {
        width: 100%;
        padding: 10px 5px;
        padding-bottom:0px;
        padding-top:10px;
    }

    .modal > .actions {
        width: 100%;
        padding: 10px 10px;
        margin: auto;
        text-align: right;
        padding-bottom:0px;
        padding-top:5px;
    }

 /*   .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }*/
        .spinner-grow {
    width: 5rem;
    height: 5rem;
}

.text-dark {
    color: #ffffff !important;

}
a.text-dark:hover, a.text-dark:focus {
    color: white !important;
}

.table-bordered thead th {
    background-color: #548ebc;
    color: white;
    text-align: center !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #e9e9e9;
}

.react-tabs__tab--selected {
    background: #548ebc;
    color: white;
}
.table-hover tbody tr:hover {
    background-color: rgb(174 205 227)!important;
}
.modal > .header {
    color: white;
    background-color: #548ebc;
    margin: 0px;
    text-align: left;
}
.modal > .header_review {
    color: white;
    background-color: darkgreen;
    margin: 0px;
    text-align: left;
}
.popup-content {
    padding: 3px;
    width:1090px;
}
button.close {
    color: #f3f3f3;
    opacity: 1;
    margin-right: 7px;
    margin-top:5px;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    color: white;
    opacity: 1;
    margin-right: 5px;
}

.card-body {
    padding: 5px;
}
.animated fadeIn{
    padding:10px;
}
.align-center{
    text-align:center;
}
.align-right {
    text-align: right;
}
/*table, th, td {
    border: 0.5px solid #548ebc !important;
   
}*/
.edit-icon {
    background-image: url('icons/edit.png');
    width: 32px;
    height: 32px;
    background-size: cover;
    cursor: pointer;
}
.delete-icon {
    background-image: url('icons/delete.png');
    width: 32px;
    height: 32px;
    background-size: cover;
    cursor: pointer;
}
.undo-icon {
    background-image: url('icons/undo.png');
    /*background-color:orange;*/
    width: 32px;
    height: 32px;
    background-size: cover;
    cursor: pointer;
}
.review-icon {
    background-image: url('icons/review.png');
    width: 32px;
    height: 32px;
    background-size: cover;
    cursor: pointer;
}
.selectedrow {
    background-color: rgb(174 205 227) !important;
}
.width-100{
    width:100px;
}
.tab-section {
    padding-left: 16px;
    padding-top: 16px;
}
.help-text {
    background-color: #fcfbd1;
    padding: 5px;
}

.grammar-table{direction:rtl; }
    .grammar-table table {border-collapse:separate; width:100%}
.grammar-table input {font-family:AlMushaf; font-size:20px; width:125px; text-align:center}
    .grammar-table input[name='baab_code'] { font-family: sans-serif; }

    .grammar-table th {font-family:AlMushaf; background-color: #baddf9; color: #000000; padding-top:10px; padding-bottom:10px; font-size:12pt; font-weight:normal; text-align:center; line-height:5px;}
    .grammar-table td {text-align:center}

.frm-input input{}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
    font-family:sans-serif;
    font-size:10pt;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-wrapper span:hover {
    /*background-color: #C5ECF1;
    border-color: rgba(0,0,0,.15);*/
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.react-contextmenu-special-item{
    color:red;
    font-weight:bold;
}

#categories-legend {text-align:right;}
#categories-legend > div {
    padding-bottom: 10px;
    border-right: 1px solid lightgray;
    margin-bottom: 10px;
}
#categories-legend div div{display:inline-block; padding:3px; font-family:Alvi;}

.padding10x{padding:10px;}
.paddingLftRgt10x{padding:10px;}
.paddingLftRgt10xImp{padding-left:10px!important; padding-right:10px!important;}
.paddingLftRgt20x{padding:20px;}
.paddingTopBtm10x{padding:10px;}
.paddingTopBtm20x{padding:20px;}


.nahv-legend {margin-right:10px; font-size:16px; color:white;}
.context-menu {font-family:Alvi; border:0px none transparent!important; font-weight:bold; font-size:18px; text-align:right; color:white; margin:2px;}
.colorAfaal {background-color: #cc0000; }
.colorAsma {background-color: #0099CC; }
.colorFaael {background-color: #33CC66; }
.colorMafool {background-color: #FF00FF; }
.colorHaal {background-color: #6e1835; }
.colorSift {background-color: #0000FF; }
.colorTamyuz {background-color: #999933; }
.colorZarf {background-color: #663399; }
.colorTakeed {background-color: #996633; }
.colorZameerShaan {background-color: #9ab416; }
.colorIsmFel {background-color: #0b503d; }


.align-left{text-align: left;}
.align-right{text-align: right;}

.sarf-properties-dropdown {
    color: #000000!important;
    padding:5px;
    font-family: AlMushaf;
    font-size: 17px;
}
.sarf-properties-dropdown option {font-family:sans-serif; font-size:12px}
    
.sarf-properties-dropdown option.select-option{font-family: AlMushaf;direction:rtl;font-size: 20px;}

.ddl-row{margin-bottom: 5px;/* border-bottom: 1px solid lightgray; *//* border-top: 1px solid lightgray; */padding-top: 5px;padding-bottom: 5px;}
.ddl-row label{margin:0px; margin-bottom:5px}
.frm-input-sarf label{margin-bottom:0px;}

.frm-btm-border {border-bottom: 1px solid lightgray;}

.LargeAlMushafFont{font-family: AlMushaf; font-size: 45px; line-height: 60px;}
.inlineLargeAlMushafFont {font-family: AlMushaf; font-size: 45px; line-height: 60px; display:inline-block; margin-left:10px;}

.direction-rtl{direction:rtl;}

.fileLabel{font-size:10px; margin-bottom:5px; padding-right:5px;}
.fileInput { padding: 0; border: 1px solid #eee; background-color: #fff; box-shadow: none;width:160px }

.remove-btn{margin-left:10px;}
.attachment_name{padding-left:5px;}

.sarf-properties-dropdown {
    color: #000000 !important;
    padding: 5px;
    font-family: AlMushaf;
    font-size: 15px;
}

.file-upload-content{ padding-top: 5px; padding-bottom: 5px; background: #e2eff9; padding-left: 10px; padding-right: 10px; margin-left: 10px; margin-right: 10px; margin-bottom:10px;}

.divDialogContentContainer{height:365px; overflow:auto;}
.divDialogContentContainerReview {height: 470px; overflow:auto;}

.msg-div{background-color:antiquewhite; padding:10px; margin:5px;}

.review-list .table-bordered th {background:darkgreen;}

.notification-circle{
    display: block;
    background: red;
    border-radius: 32px;
    position: absolute;
    /* padding: 20px; */
    padding-left: 5px;
    padding-right: 5px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    top:-10px;
    right:-5px;
}

.rejected {background-color: #f6cbcb !important;}
.table-striped tbody tr.rejected:nth-of-type(odd):hover{background-color: #f6cbcb !important;}

.difference { font-size: 11pt; padding: 5px; position: absolute; right: 0px; background-color: #ffff99; top: 0px; border-radius: 16px; width: 16px; text-align: center; height: 16px; line-height: 16px; }
    .difference a {color:red; text-decoration:none;}
.difference-text { padding-left: 5px; padding-right: 5px; margin-top: 5px; display: inline-block; color: red; background-color: #ffff99; }

.arabic-container .difference-text { font-family:AlMushaf; font-size:20px; }
.urdu-container .difference-text { font-family:Alvi; font-size:20px; }
.difference-input-english {} 

.difference-text-highlight {background-color: #ffff99; }

.text-align-right{text-align:right;}

tr.red td input {color:red; background:transparent; border:0px none transparent; background-color: #ffff99;}

tr.red td input[value=""]{background-color: transparent!important;}
.text-align-left{text-align:left;}